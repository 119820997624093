.root {
  position: absolute;
  z-index: 100;
  bottom: 0;
  right: 0;
  left: 0;
  max-height: calc(100% - 40px);
  @media (min-width: $mantine-breakpoint-md) {
    max-height: none;
    right: auto;
    top: 0;
    width: 26em;
  }
}

.fullHeight {
  height: calc(100% - 40px);
  @media (min-width: $mantine-breakpoint-md) {
    height: auto;
  }
}

.close {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1000;
}

.toggle {
  position: absolute;
  background: white;
  box-shadow: 0 0 0 2px #0000001a;
  z-index: -1;
  border-radius: 4px 4px 0 0;
  top: -35px;
  right: auto;
  left: 75%;
  width: 60px;
  height: 35px;

  @media (min-width: $mantine-breakpoint-md) {
    left: auto;
    top: calc(30%);
    right: -25px;
    width: 25px;
    height: 100px;
    border-radius: 0 4px 4px 0;
  }
}

.caretVariants * {
  display: none;
}

.caretVariants *:first-child {
  @media (min-width: $mantine-breakpoint-md) {
    display: block;
  }
}

.caretVariants *:last-child {
  @media (max-width: calc($mantine-breakpoint-md - 1px)) {
    display: block;
  }
}

.toggle button {
  position: absolute;
  background: none;
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mantine-color-gray-8);
}

.inner {
  overflow: auto;
  height: 100%;
}

.folded {
  @media (min-width: $mantine-breakpoint-md) {
    top: calc(100% - 50px);
    top: 0;
    width: 7px;
  }
}

.folded .inner {
  @media (min-width: $mantine-breakpoint-md) {
    display: none;
  }
}
