:root {
  --header-height: 70px;
  --primary-color-light: #96b300;
  --primary-color-dark: #5d7000;

  --controls-spacing: 10px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  /* background-color: #96B300; */
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.maplibregl-ctrl-group button {
  width: 40px;
  height: 40px;
}
