.root {
  height: 100%;
  position: relative;
}

.controls,
.legend {
  position: absolute;
  left: var(--controls-spacing);
  display: flex;
  flex-direction: column;
  gap: var(--mantine-spacing-xs);
  align-items: flex-start;
}

.controls {
  top: var(--controls-spacing);
  pointer-events: none;
}

.controls > * {
  pointer-events: auto;
}

.styleControls {
  left: auto;
  right: var(--controls-spacing);
  @media (max-width: calc($mantine-breakpoint-md - 1px)) {
    top: 70px;
  }
}

.legend {
  bottom: 50;
}

.infoBoxes {
  top: 50;
  right: 50;
}

.locationSearch {
  box-shadow: 0 0 0 1px #0000001a;
  border-radius: 4px;
}

.loader {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 60px;
  box-shadow: var(--mantine-shadow-sm);
  top: calc(var(--controls-spacing) + var(--header-height));
  right: var(--controls-spacing);
  width: 40;
  height: 40;
  padding: 5;
  @media (min-width: calc($mantine-breakpoint-md)) {
    top: var(--controls-spacing);
  }
}
