.root {
  @media (max-width: calc($mantine-breakpoint-md - 1px)) {
    max-height: 350px;
    overflow: scroll;
  }
}

.item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid
    light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-5));
  padding: var(--mantine-spacing-xs);
  padding-top: 0;
  background-color: light-dark(
    var(--mantine-color-white),
    var(--mantine-color-dark-5)
  );
}

.itemDragging {
  box-shadow: var(--mantine-shadow-sm);
}

.symbol {
  font-size: 30px;
  font-weight: 700;
  width: 60px;
}

.dragHandle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1));
  padding-left: 0;
  padding-right: var(--mantine-spacing-md);
}
